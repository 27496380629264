<template>
  <div>
    <header-label
      :label="$t('account.header')"
      prepend-icon="$g_account" />
    <div class="mb-5">
      <email-form />
    </div>
    <change-password-form />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmailForm from '@/views/Account/components/EmailForm.vue'
import ChangePasswordForm from '@/views/Account/components/ChangePasswordForm.vue'

export default {
  name: 'Account',
  components: {
    EmailForm,
    ChangePasswordForm
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission'
    })
  }
}
</script>

<style>

</style>

<style scoped>

</style>
