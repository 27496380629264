<template>
  <v-card
    class="app-card"
    flat>
    <v-card-title class="d-flex align-center pb-0">
      <title-label
        prepend-icon="mdi-lock-outline"
        :label="$t('account.changePassword.title')" />
      <v-spacer />
      <v-btn
        v-if="expanded"
        color="blue darken-1"
        text
        @click="expand(false)"
      >
        {{ $t('account.changePassword.btn.close') }}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        text
        @click="expand(true)">
        {{ $t('account.changePassword.btn.edit') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-expand-transition>
        <div v-if="expanded">
          <v-form
            ref="form"
            v-model="valid"
            :readonly="isSubmitting"
            lazy-validation
            @submit.prevent="submit()">
            <v-row>
              <v-col
                cols="5"
                md="2"
                offset-md="1"
                class="pb-0">
                <text-field-label
                  :label="$t('account.changePassword.field.password.label')"
                  required />
              </v-col>
              <v-col
                cols="7"
                md="4"
                class="pb-0">
                <v-text-field
                  v-model="form.currentPassword"
                  :rules="rule.currentPassword"
                  :error-messages="error.currentPassword"
                  :placeholder="$t('account.changePassword.field.password.placeholder')"
                  type="password"
                  required
                  dense
                  outlined
                  @input="markAsDirty($event)" />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="5"
                md="2"
                offset-md="1"
                class="pb-0">
                <text-field-label
                  :label="$t('account.changePassword.field.newPassword.label')"
                  required />
              </v-col>
              <v-col
                cols="7"
                md="4"
                class="pb-0">
                <v-text-field
                  v-model="form.password"
                  :rules="rule.password"
                  :error-messages="error.password"
                  :placeholder="$t('account.changePassword.field.newPassword.placeholder')"
                  type="password"
                  required
                  dense
                  outlined
                  @input="markAsDirty($event)" />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="5"
                md="2"
                offset-md="1">
                <text-field-label
                  :label="$t('account.changePassword.field.confirmNewPassword.label')"
                  required />
              </v-col>
              <v-col
                cols="7"
                md="4">
                <v-text-field
                  v-model="form.passwordConfirmation"
                  :rules="rule.passwordConfirmation"
                  :error-messages="error.passwordConfirmation"
                  :placeholder="$t('account.changePassword.field.confirmNewPassword.placeholder')"
                  type="password"
                  required
                  dense
                  outlined
                  @input="markAsDirty($event)" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                offset="5"
                offset-md="3"
                cols="4">
                <save-change-button
                  class="mr-1"
                  :state="state"
                  :disabled="!valid"
                  :label="$t('account.changePassword.btn.save')"
                  @click="submit()" />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import humps from 'humps'
import { mapGetters } from 'vuex'
import { UPDATE_USER } from '@/resources/graphql'

export default {
  data () {
    return {
      expanded: false,
      state: 'ready',
      valid: true,
      form: {
        currentPassword: '',
        password: '',
        passwordConfirmation: ''
      },
      rule: {
        currentPassword: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => (v && v.length >= 6) || this.$t('app.validate.max', [6])
        ],
        password: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => (v && v.length >= 6) || this.$t('app.validate.max', [6])
        ],
        passwordConfirmation: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => (v && v.length >= 6) || this.$t('app.validate.max', [6]),
          (v) => v === this.form.password || this.$t('app.validate.match', [this.$t('account.changePassword.field.newPassword.label')])
        ]
      },
      error: {
        currentPassword: [],
        password: [],
        passwordConfirmation: []
      }
    }
  },
  computed: {
    ...mapGetters({
      userId: 'Auth/userId'
    }),
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    expand (isExpand) {
      this.expanded = isExpand
    },
    markAsDirty () {
      this.error = {
        currentPassword: [],
        password: [],
        passwordConfirmation: []
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        this.error = {
          currentPassword: [],
          password: [],
          passwordConfirmation: []
        }
        const res = await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            input: {
              id: this.userId,
              currentPassword: this.form.currentPassword,
              password: this.form.password,
              passwordConfirmation: this.form.passwordConfirmation
            }
          }
        })

        if (res) {
          const { data: { updateUser } } = res
          if (updateUser.errors && Object.keys(updateUser.errors).length) {
            this.state = 'error'
            this.error = humps.camelizeKeys(updateUser.errors)
          } else {
            this.state = 'success'
            this.$refs.form.reset()
          }
          setTimeout(() => {
            this.state = 'ready'
          }, 3000)
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
