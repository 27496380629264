<template>
  <v-form
    ref="form"
    v-model="valid"
    :readonly="isSubmitting"
    lazy-validation
    @submit.prevent="submit()">
    <v-card
      class="app-card"
      flat>
      <v-card-title>
        <title-label
          :label="$t('account.email.title')"
          prepend-icon="mdi-email-outline" />
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <template v-if="expanded">
            <v-text-field
              v-model="form.email"
              :placeholder="$t('account.email.field.email.placeholder')"
              :rules="rule.email"
              :error-messages="error.email"
              autofocus
              type="email"
              dense
              required
              outlined
              @input="markAsDirty($event)"
            />
            <v-spacer />
            <save-change-button
              class="mr-1"
              :state="state"
              :label="$t('account.email.btn.save')"
              :disabled="!isDirty || !valid"
              @click="submit()" />
            <v-btn
              color="blue darken-1"
              text
              @click="expand(false)">
              {{ $t('account.email.btn.close') }}
            </v-btn>
          </template>
          <template v-else>
            <text-field-label
              class="input-text"
              :label="email" />
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="expand(true)">
              {{ $t('account.email.btn.edit') }}
            </v-btn>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_USER, UPDATE_USER } from '@/resources/graphql'

export default {
  apollo: {
    user: () => ({
      fetchPolicy: 'no-cache',
      query: GET_USER,
      variables () {
        return {
          id: this.userId
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
        this.setLoading(isLoading)
      },
      result ({ data: { user } }) {
        this.form.email = user.email
        this.setEmail(user.email)
      }
    })
  },
  data () {
    return {
      expanded: false,
      state: 'ready',
      isDirty: false,
      isFetching: false,
      valid: true,
      form: {
        email: ''
      },
      rule: {
        email: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => /.+@.+\..+/.test(v) || this.$t('app.validate.email')
        ]
      },
      error: {
        email: []
      }
    }
  },
  computed: {
    ...mapGetters({
      userId: 'Auth/userId',
      email: 'Setting/email'
    }),
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    ...mapActions({
      setEmail: 'Setting/setEmail',
      setLoading: 'Notification/setLoading'
    }),
    expand (isExpand) {
      this.expanded = isExpand
    },
    markAsDirty () {
      this.isDirty = true
      this.error = {
        email: []
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        this.error = {
          email: []
        }
        const res = await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            input: {
              id: this.userId,
              email: this.form.email
            }
          }
        })

        if (res) {
          const { data: { updateUser } } = res
          if (updateUser.errors && Object.keys(updateUser.errors).length) {
            this.state = 'error'
            this.error = updateUser.errors
          } else {
            this.state = 'success'
            this.setEmail(updateUser.user.email)
          }
          setTimeout(() => {
            this.state = 'ready'
          }, 3000)
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>
  .input-text {
    height: 24px;
  }
</style>
