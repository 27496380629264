var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { readonly: _vm.isSubmitting, "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit()
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "app-card", attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("account.email.title"),
                  "prepend-icon": "mdi-email-outline"
                }
              })
            ],
            1
          ),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _vm.expanded
                  ? [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: _vm.$t(
                            "account.email.field.email.placeholder"
                          ),
                          rules: _vm.rule.email,
                          "error-messages": _vm.error.email,
                          autofocus: "",
                          type: "email",
                          dense: "",
                          required: "",
                          outlined: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.markAsDirty($event)
                          }
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      }),
                      _c("v-spacer"),
                      _c("save-change-button", {
                        staticClass: "mr-1",
                        attrs: {
                          state: _vm.state,
                          label: _vm.$t("account.email.btn.save"),
                          disabled: !_vm.isDirty || !_vm.valid
                        },
                        on: {
                          click: function($event) {
                            return _vm.submit()
                          }
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.expand(false)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("account.email.btn.close")) +
                              " "
                          )
                        ]
                      )
                    ]
                  : [
                      _c("text-field-label", {
                        staticClass: "input-text",
                        attrs: { label: _vm.email }
                      }),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.expand(true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("account.email.btn.edit")) + " "
                          )
                        ]
                      )
                    ]
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }