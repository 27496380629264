var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: { label: _vm.$t("account.header"), "prepend-icon": "$g_account" }
      }),
      _c("div", { staticClass: "mb-5" }, [_c("email-form")], 1),
      _c("change-password-form")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }